import Alpine from "alpinejs"
import Swiper from "swiper"
import '~/styles/swiper-bundle.min.css'

export function sidecartUpsell(blockItemsIdArray) {

  const sidecartUpsellSwiper = new Swiper(".sidecart-upsell-swiper", {
    slidesPerView: 1.3,
    spaceBetween: 8,
    centeredSlides: true,
    pagination: {
      el: "#upsell-swiper-pagination",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 1,
    },
  })

  const sidecartUpsellSwiperEmpty = new Swiper(".sidecart-upsell-swiper-empty", {
    slidesPerView: 1.3,
    spaceBetween: 8,
    centeredSlides: true,
    pagination: {
      el: "#upsell-swiper-pagination",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 1,
    },
  })
  return {
    init() {
      this.refreshSidecartUpsellSwiper()
    },
    enabledProducts: blockItemsIdArray,
    disabledProducts: Alpine.store("cart").data?.items.map(
      (item) => `${item.product_id}`
    ),
    activeProducts: blockItemsIdArray.filter((id) => {
      return !Alpine.store("cart")
        .data?.items.map((item) => `${item.product_id}`)
        .includes(id)
    }),
    getSignatureBundleProductsIdArray() {
      const keywords = ["p-ball", "p-band", "precision-mat", "membership"]
      let idList = Alpine.store("products").data?.filter((product) =>
        keywords.some((keyword) => product.handle.includes(keyword))
      )

      idList = idList.map((product) => `${product.id}`)
      return idList
    },
    getTransformationBundleProductsIdArray() {
      const keywords = [
        "p-ball",
        "p-band",
        "precision-mat",
        "membership",
        "p-3",
        "slant",
        "light-ankle-band",
        "heavy-ankle-band",
        "2lb-hand",
        "massage-ball",
        "precision-foam",
        "gliders",
        "ankle-weights",
      ]
      let idList = Alpine.store("products").data?.filter((product) =>
        keywords.some((keyword) => product.handle.includes(keyword))
      )

      idList = idList.map((product) => `${product.id}`)
      return idList
    },
    async processBundles() {
      if (Alpine.store("cart").data?.items.length > 0) {
        Alpine.store("cart").data.items.forEach((item) => {
          if (item.handle.includes("signature")) {
            this.enabledProducts = this.enabledProducts.filter(
              (item) => !this.getSignatureBundleProductsIdArray().includes(item)
            )
          }
          if (item.handle.includes("transformation")) {
            this.enabledProducts = this.enabledProducts.filter(
              (item) =>
                !this.getTransformationBundleProductsIdArray().includes(item)
            )
          }
        })
      }
    },
    async refreshSidecartUpsellSwiper() {
      await this.processBundles()
      this.activeProducts = this.enabledProducts.filter((id) => {
        return !Alpine.store("cart")
          .data?.items.map((item) => `${item.product_id}`)
          .includes(id)
      })
      this.disabledProducts = Alpine.store("cart").data?.items.map(
        (item) => `${item.product_id}`
      )
      setTimeout(() => {
        console.log('sidecartUpsellSwiper', sidecartUpsellSwiper)
        // there can be multiple instances of the same swiper
        // so we have to update them all
        sidecartUpsellSwiper.update()
        sidecartUpsellSwiperEmpty.update()
      }, 500)
    },
  }
}


Alpine.store('sidecartUpsell', sidecartUpsell)